// TourGuide.js
import React from 'react';
import Tour from 'reactour';
import { useTranslation } from 'react-i18next';

const VisualHelper = ({ isOpen, onRequestClose }) => {
    const { t } = useTranslation();

    const steps = [
        {
            selector: '',
            content: t('tour.step1')
        },
        {
            selector: '#units',
            content: t('tour.step2'),
        },
        {
            selector: '#width',
            content: (
                <><p>{t('tour.step3')}
                </p>
                <span className='font-bold text-xs'>{t('disclaimerWidth')}</span></>),
        },
        {
            selector: '#height',
            content: t('tour.step4'),
        },
        {
            selector: '#add-wall',
            content: t('tour.step6'),
        },
    ];
    return (
        <Tour
            steps={steps}
            isOpen={isOpen}
            startAt={0}
            onRequestClose={onRequestClose}
            rounded={5}
            accentColor="#007bff"
            disableInteraction={true}
            closeWithMask={true}
        />
    );
};

export default VisualHelper;