import { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { GrFormPrevious } from "react-icons/gr";
import { GrFormView } from "react-icons/gr";
import { GrHide } from "react-icons/gr";
import { GrVmMaintenance } from "react-icons/gr";
import { GrThreeDEffects } from "react-icons/gr";
import logo from '../../images/logo.png'
import config from '../../config.json';

export default function Result() {
    const location = useLocation();
    const navigation = useNavigate();
    const walls = location.state.walls;
    const noSolution = location.state.noSolution ?? false;
    const changedStart = location.state.changedStart ?? false;
    const wallResults = location.state.wallResults;
    const { t, i18n } = useTranslation();

    const
        [packs, setPacks] = useState([]),
        [showDetails, setShowDetails] = useState([]),
        [sendingMail, setSendingMail] = useState(false),
        [soldSeparately, setSoldSeparately] = useState([])
        ;

    useEffect(() => {

        //calculate packs with count
        let packsCalculated = [];
        let newSoldSeparately = [];
        location.state.profiles.forEach(profile => {
            let pack = packsCalculated.find(p => p.pack.type === profile.type);
            if (pack) {
                pack.count++;
            } else {
                packsCalculated.push({ pack: profile, count: 1 });
                if (profile.soldSeparately) {
                    newSoldSeparately.push(profile);
                }
            }
        });
        setPacks(packsCalculated);
        setSoldSeparately(newSoldSeparately);
        setShowDetails(packsCalculated.map(p => false));

    }, [])

    const goBack = () => {
        navigation("/", { state: { walls: walls, showVisualHelper: false } })
    }

    //download pdf
    const printHandler = async () => {


        //get pdf from api
        let response = await fetch(`${config.api}/pdf`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                language: i18n.language,
                packs: packs,
                walls: walls,
                wallResults: wallResults
            }),
        }).then(val => val.blob());

        //download file
        download('cladix-pack.pdf', URL.createObjectURL(response));
    }

    const [isModalOpen, setModalOpen] = useState(false);
    const [email, setEmail] = useState("");

    // Function to toggle the modal
    const toggleModal = () => {
        setModalOpen((prev) => !prev);
    };

    // Function to handle sending the configuration
    const sendConfiguration = async () => {
        //set sending mail to true
        setSendingMail(true);

        //get pdf from api
        let response = await fetch(`${config.api}/pdf`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                language: i18n.language,
                packs: packs,
                email: email,
                walls: walls,
                wallResults: wallResults
            }),
        }).then(val => val.json());

        // Close the modal after sending
        toggleModal();
        setSendingMail(false);

        // Show a success message
        if (response.status !== 'OK') {
            alert(t("Er is iets fout gegaan, probeer opnieuw over 10min."));
        }
        else{
            //Script for measuring analytics
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
            'event': 'rekentool_form_success',
            });
        }
    };

    const download = (filename, url) => {
        var element = document.createElement('a');
        element.setAttribute('href', url);
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
        'event': 'rekentool_pdf_download',
        });
    }

    let totalWeight = 0;
    let individualProfiles = [];
    let individualProfilesCountTotal = 0;

    return (
        <div>
            <div><div className="bg-white rounded shadow-md w-full sm:max-w-lg md:max-w-lg lg:max-w-xl px-5 pt-5 pb-5 mt-5 container">
                <h2 onClick={goBack} className="bg-orange-500 px-4 py-2 text-white flex back-button"><GrFormPrevious /> {t('Terug')}</h2>
                <h3 className="wall-title text-xl text-center font-semibold mb-8">{t('packAmount')}</h3>
                <div className="flex">
                    <div className="overflow-x-auto text-sm mx-auto w-full">
                        <table className="min-w-full bg-white border border-gray-300">
                            <colgroup>
                                <col span="1" style={{width: '100%'}} />
                                <col span="1" style={{width: '1%'}} />
                                <col span="1" style={{width: '100%'}} />
                                <col span="1" style={{width: '1%'}} />
                                <col span="1" style={{width: '1%'}} />
                            </colgroup>
                            <thead className="bg-gray-200">
                                <tr>
                                    <th className="p-3">{t('theadType')}</th>
                                    <th className="p-3" colSpan={2}>{t('Omschrijving')}</th>
                                    <th className="p-3">{t('Gewicht')}</th>
                                    <th className="p-3">{t('theadAmount')}</th>
                                    <th className="p-3">{t('Details')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {packs.map((value, index) => {
                                    let weight = (value.pack.weight * value.count) / 1000;
                                    totalWeight += weight;
                                    if (value.pack.soldSeparately) {
                                        individualProfiles.push(value);
                                        individualProfilesCountTotal += value.count;
                                    } else {
                                        return (
                                            <>
                                                <tr>
                                                    <td className="p-3 border font-medium text-gray-900 whitespace-nowrap">
                                                        <div className="flex items-center">
                                                            <div className="mr-2" style={{ backgroundColor: value.pack.color, borderRadius: 30, padding: 5 }}>
                                                                <GrThreeDEffects className="text-white" />
                                                            </div>
                                                            {value.pack.type}
                                                        </div>
                                                    </td>
                                                    <td className="p-3 border" colSpan={2}>
                                                        {value.pack.description}
                                                    </td>
                                                    <td className="p-3 border">
                                                        {weight.toLocaleString()} {t('kg')}
                                                    </td>
                                                    <td className="p-3 border w-1/2">
                                                        <span className="mx-auto border text-center py-1 block sm:w-1/2 md:w-1/3 lg:w-1/3" style={{minWidth: 40}}>{value.count}x</span>
                                                    </td>
                                                    <td className="border p-3 text-center">
                                                        <button
                                                            className="bg-orange-500 px-2 py-2 text-white"
                                                            onClick={() => {
                                                                let newShowDetails = [...showDetails];
                                                                newShowDetails[index] = !showDetails[index];
                                                                setShowDetails(newShowDetails);
                                                            }}
                                                        >
                                                            {showDetails[index] ? <GrHide /> : <GrFormView />}
                                                        </button>
                                                    </td>
                                                </tr>
                                                {showDetails[index] &&
                                                    <>
                                                        {value.pack.profilePackLines.map((profile) => {
                                                            return (
                                                                <tr className="bg-gray-100">
                                                                    <td className="px-3 py-1 pl-8 border" colSpan={2}>
                                                                        <div className="flex items-center text-xs" style={{width:20}}>
                                                                            <div className="mr-2" style={{ backgroundColor: 'lightGrey', borderRadius: 30, padding: 5 }}>
                                                                                <GrVmMaintenance style={{ fontSize: 15 }} />
                                                                            </div>
                                                                            {profile.profile.profileCode}
                                                                        </div>
                                                                    </td>
                                                                    <td className="p-3 border text-xs" colSpan={2}>{t('product.' + profile.profile.reference)}</td>
                                                                    <td className="py-1 border text-center"><span className="mx-auto border text-center py-1 block sm:w-1/2 md:w-1/3 lg:w-1/3  text-xs" style={{minWidth: 40}}>{profile.amount * value.count}x</span></td>
                                                                    <td className="p-3 border"></td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </>
                                                }
                                            </>
                                        );
                                    }
                                })}
                                {individualProfiles.length > 0 &&
                                    <>
                                        <tr>
                                            <td className="p-3 border font-medium text-gray-900 whitespace-nowrap">
                                                /
                                            </td>
                                            <td className="p-3 border" colSpan={2}>
                                                {t('Individuele profielen')}
                                            </td>
                                            <td className="p-3 border">
                                                /
                                            </td>
                                            <td className="p-3 border w-1/2">
                                                <span className="mx-auto border text-center px-4 py-1 block sm:w-1/2 md:w-1/3 lg:w-1/3">{individualProfilesCountTotal}x</span>
                                            </td>
                                            <td className="border p-3 text-center">
                                                <button
                                                    className="bg-orange-500 px-2 py-2 text-white"
                                                    onClick={() => {
                                                        let newShowDetails = [...showDetails];
                                                        newShowDetails['ind'] = !showDetails['ind'];
                                                        setShowDetails(newShowDetails);
                                                    }}
                                                >
                                                    {showDetails['ind'] ? <GrHide /> : <GrFormView />}
                                                </button>
                                            </td>
                                        </tr>
                                        {showDetails['ind'] &&
                                            <>
                                                {individualProfiles.map((profile) => {
                                                    return (
                                                        <tr className="bg-gray-100">
                                                            <td className="p-3 border" colSpan={2}>{profile.pack.profilePackLines[0].profile.profileCode}</td>
                                                            <td className="p-3 border" colSpan={2}>{t('product.' + profile.pack.profilePackLines[0].profile.reference)}</td>
                                                            <td className="p-3 border text-center"><span className="mx-auto border text-center px-4 py-1 block sm:w-1/2 md:w-1/3 lg:w-1/3">{profile.pack.profilePackLines[0].amount * profile.count}x</span></td>
                                                            <td className="p-3 border"></td>
                                                        </tr>
                                                    )
                                                })}
                                            </>
                                        }
                                    </>
                                }

                            </tbody>
                            <tfoot>
                                <tr>
                                    <th className="p-3 border" >{t('Totaal')}</th>
                                    <th className="p-3 border" colSpan={2}></th>
                                    <th className="p-3 border">{totalWeight.toLocaleString()} {t('kg')}</th>
                                    <th className="p-3 border"></th>
                                    <th className="p-3 border"></th>
                                </tr>
                            </tfoot>
                        </table>
                        {noSolution &&
                            <div className="flex items-center p-3 mt-4 text-sm text-red-800 rounded-sm bg-red-100" role="alert">
                                <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                </svg>
                                <span className="sr-only">{t('Info')}</span>
                                <div>
                                    <span className="font-medium">{t('warning')}</span> {t('Geen gepaste oplossing gevonden.')}
                                </div>
                            </div>
                        }
                        {changedStart &&
                            <div className="flex items-center p-3 mt-4 text-sm text-red-800 rounded-sm bg-red-100" role="alert">
                                <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                </svg>
                                <span className="sr-only">{t('Info')}</span>
                                <div>
                                    <span className="font-medium">{t('warning')}</span> {t('Er is geen gepaste oplossing met het geselecteerde startprofiel, een alternatief startprofiel is toegepast.')}
                                </div>
                            </div>
                        }
                        {soldSeparately.map((profile, index) => {
                            return (
                                <div className="flex items-center p-3 mt-4 text-sm text-yellow-800 rounded-sm bg-orange-100" role="alert">
                                    <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                    </svg>
                                    <span className="sr-only">{t('Info')}</span>
                                    <div>
                                        <span className="font-medium">{t('warning')}</span> {profile.type} {t('wordt apart verkocht.')}
                                    </div>
                                </div>
                            );
                        })}
                        <div className="flex items-center p-3 mt-4 text-sm text-yellow-800 rounded-sm bg-orange-100" role="alert">
                            <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                            </svg>
                            <span className="sr-only">{t('Info')}</span>
                            <div>
                                <span className="font-medium">{t('warning')}</span> {t('warningText')}
                            </div>
                        </div>
                    </div>
                </div>

                {isModalOpen && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center">
                        <div className="absolute inset-0 bg-gray-800 opacity-50"></div>
                        <div className="relative bg-white p-6 rounded shadow-md w-1/4">
                            <h2 className="text-lg font-semibold mb-4">{t('Stuur configuratie')}</h2>
                            <label for="email" class="block mb-2 px-1.5 text-sm font-medium text-gray-900">{t('Email')}</label>
                            <input type="email" value={email}
                                onChange={(e) => setEmail(e.target.value)} id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@flowbite.com" required></input>
                            {sendingMail ?
                                <span>{t("Sending mail...")}</span> :
                                <div className="flex justify-center mt-4">
                                    <a
                                        className="px-4 py-2 mr-2 bg-orange-500 text-white rounded hover:bg-blue-600"
                                        onClick={sendConfiguration}
                                    >
                                        {t('Stuur')}
                                    </a>
                                    <button
                                        className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                                        onClick={toggleModal}
                                    >
                                        {t('Annuleren')}
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                )}
            </div>
                <button
                    className="bg-orange-500 px-4 py-2 text-white flex mt-5 mx-auto"
                    onClick={toggleModal}
                >
                    {t('Stuur configuratie naar email')}
                </button>
                <button onClick={printHandler} className="bg-orange-500 px-4 py-2 text-white flex mt-5 mx-auto">{t('Opslaan als PDF')}</button></div>
        </div>
    );
}