import React from 'react';
import ReactModal from 'react-modal';
import { GrFormClose } from 'react-icons/gr';

export default function Modal({ isOpen, onRequestClose, title, children }) {

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={{
                overlay: {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                },
                content: {
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 0,
                    inset: '0px',
                    width: '90vw',
                    maxWidth: '600px',
                    maxHeight: '90vh',
                }
            }}
        >
            <div className='sticky top-0 left-0 right-0 z-10 flex flex-row justify-between'>
                <span className='absolute top-0 bottom-0 left-0 right-0 backdrop-blur bg-white/70 border-b rounded-t'></span>
                <h3 className='z-20 text-left text-lg text-gray-500 font-semibold ml-5 mt-5'>{title}</h3>
                <button
                    className={'m-5'}
                    onClick={onRequestClose}
                >
                    <GrFormClose
                        className={"blur-none text-2xl bg-red-500 text-white rounded-full hover:bg-red-800"}
                    />
                </button>
            </div>
            <div className="flex flex-col grow">
                <div className='grow shrink flex flex-col justify-center p-5 z-3'>
                    {children}
                </div>
            </div>
        </ReactModal>
    );
};