import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslation from './translations/en.json';
import esTranslation from './translations/es.json';
import beTranslation from './translations/be.json'
import frTranslation from './translations/fr.json';

// The translations
const resources = {
    en: {
        translation: enTranslation,
    },
    es: {
        translation: esTranslation,
    },
    be: {
        translation: beTranslation,
    },
    fr: {
        translation: frTranslation,
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        detection: {
            order: ['navigator', 'htmlTag', 'path', 'subdomain'], // Language detection order
            caches: ['cookie'], // Cache the language in a cookie
        },
        fallbackLng: 'be', // default language
        keySeparator: '.', // allows for nested keys
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;