import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import { Redirect, Link, useNavigate, useLocation } from 'react-router-dom';
import VisualHelper from '../VisualHelper/VisualHelper';
import { useTranslation } from 'react-i18next';
import OptimizedProfilesCalculator from '../../utils/OptimizedProfilesCalculator';
import CalculatorForm from './CalculatorForm';
import { GrBottomCorner, GrTopCorner, GrFormCut, GrTrash, GrEdit } from 'react-icons/gr';
import Modal from '../Modal';

import attachedInnerCornerColor from '../../images/attachedInnerCorner-Color.png';
import attachedOuterCornerColor from '../../images/attachedOuterCorner-Color.png';
import detachedColor from '../../images/detached-color.png';

export default function Calculator({ showVisualHelper, setShowVisualHelper }) {
    const navigation = useNavigate();
    const location = useLocation();

    const { t, i18n } = useTranslation();
    const [openVisualHelper, setOpenVisualHelper] = useState(true);

    const [units, setUnits] = useState(localStorage.getItem('units') || 'cm');
    const [walls, setWalls] = useState([]);
    const [currentWall, setCurrentWall] = useState({
        width: '',
        height: '',
        isTouching: false,
        angle: ''
    });
    const [editWallIndex, setEditWallIndex] = useState(null);
    const [showResult, setShowResult] = useState(false);
    const [showAngleModal, setShowAngleModal] = useState(false);
    const [showFormModal, setShowFormModal] = useState(false);

    // Handle unit change
    const handleUnitChange = () => {
        const prevUnit = units === 'mm' ? 'cm' : 'mm';
        console.log("handleUnitChange");
        setCurrentWall((prevWall) => ({
            ...prevWall,
            width: prevWall.width === '' ? '' : convertLength(prevWall.width, units, prevUnit),
            height: prevWall.height === '' ? '' : convertLength(prevWall.height, units, prevUnit),
        }));

        const updatedWalls = walls.map((wall) => ({
            ...wall,
            width: convertLength(wall.width, units, prevUnit),
            height: convertLength(wall.height, units, prevUnit),
        }));

        setWalls(updatedWalls);
    };

    function handleDeleteWall(index) {
        setWalls((prevWalls) => prevWalls.filter((_, i) => i !== index));
    };

    const handleCalculate = async () => {
        const profilesCalculator = new OptimizedProfilesCalculator();
        let result = await profilesCalculator.calculateProfiles(walls, units);
        let noSolution = profilesCalculator.getNoSolution();
        let changedStart = profilesCalculator.getChangedStart();
        let wallResults = profilesCalculator.getWallResults();
        navigation("/result", { state: { profiles: result, walls: walls, noSolution: noSolution, changedStart: changedStart, wallResults: wallResults } })
    };

    const handleVisualHelperClose = () => {
        setOpenVisualHelper(false);
        setShowVisualHelper(false); // Set showVisualHelper to false when VisualHelper is closed
    };

    const convertLength = (width, fromUnit, toUnit) => {
        const units = {
            mm: 10,
            cm: 1
        };

        return (width * units[toUnit]) / units[fromUnit];
    };

    const toggleUnits = (e) => {
        setUnits(e.target.checked ? 'cm' : 'mm');
        localStorage.setItem('units', e.target.checked ? 'cm' : 'mm');

        handleUnitChange();
    };

    const handleEditWall = (index) => {
        setEditWallIndex(index);
        setShowFormModal(true);
    };

    useEffect(() => {
        if (location.state) {
            const resultPageWalls = location.state.walls;
            const resultShowVisualHelper = location.state.showVisualHelper;

            console.log(resultShowVisualHelper);

            if (resultPageWalls) {
                if (units === 'cm') {
                    const convertedResultPageWalls = resultPageWalls.map((wall) => ({
                        ...wall,
                        width: convertLength(wall.width, 'mm', units),
                        height: convertLength(wall.height, 'mm', units),
                    }));
                    setWalls(convertedResultPageWalls);
                } else {
                    setWalls(resultPageWalls);
                }
            }

            if (resultShowVisualHelper === false) {
                showVisualHelper = false;
            }
        }
    }, [location.state, setShowVisualHelper]);

    return (
        <>
            <VisualHelper isOpen={showVisualHelper} onRequestClose={() => handleVisualHelperClose()} />
            {!showResult && (
                <div className="bg-white mx-auto rounded shadow-md sm:max-w-lg md:max-w-lg lg:max-w-xl mt-5 container">
                    <div className="flex flex-wrap gap-4 px-5 pt-10 pb-5">
                        <h2 className="wall-title font-semibold mb-4 flex-1 text-md md:text-xl lg:text-xl">{t('measurementPerWall')}</h2>
                        <div className='bg-slate-100 flex rounded-sm px-2 py-1 rounded-md border' id="units">
                            <div className='inline-flex items-center'>
                                <svg version="1.1" id="ruler" height="20" width="20" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 20 20" xmlSpace="preserve">


                                    <path fill="#999999;" d="M19.59,5.2v9.59H0.41V5.2H19.59z M1.61,13.6h16.79V6.4h-1.8v2.4h-1.2V6.4h-1.2v4.2H13V6.4h-1.2v2.4
                                                h-1.2V6.4H9.4v4.2H8.2V6.4H7v2.4H5.8V6.4h-1.2v4.2h-1.2V6.4h-1.8V13.6z"/>
                                </svg>
                            </div>
                            <div className='flex items-center'>
                                <span className="mx-2 text-sm font-medium text-gray-900 dark:text-gray-300">mm</span>
                                <label className="relative inline-flex items-center cursor-pointer">
                                    <input type="checkbox" className="sr-only peer" checked={units === 'cm'}
                                        onChange={toggleUnits} />
                                    <div className="w-11 h-6 bg-orange-500 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-orange-500"></div>
                                </label>
                                <span className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">cm</span>
                            </div>
                        </div>
                    </div>
                    {walls.length < 1 &&
                        <CalculatorForm
                            units={units}
                            walls={walls}
                            onAddWall={(newWall) => {
                                setWalls((prevWalls) => [...prevWalls, newWall]);
                            }}
                        />
                    }
                    {walls.length > 0 && (
                        <div className="mt-4 px-5">
                            <h3 className="text-lg text-gray-500 font-semibold">{t('walls')}</h3>
                            <ul className="divide-y divide-solid border border-solid">
                                {walls.map((wall, index) => (
                                    <li key={index} className="p-1 relative flex flex-row items-center">
                                        <span className="opacity-50">#{index + 1}</span>
                                        <p className="ml-2 flex-grow flex-shrink">
                                            <span className='pr-1'>L: <span className='font-semibold'>{wall.width}{units}</span></span> <span className='pr-1'>H: <span className='font-semibold'>{wall.height}{units}</span> {wall.restartProfile && !wall.isTouching && <span className='pr-1'>({t('Vlak')})</span>}</span>
                                        </p>
                                        <div className="ml-2">
                                            <button
                                                onClick={() => handleEditWall(index)}
                                                className="mx-auto text-orange-500 border bg-white hover:bg-orange-500 hover:text-white font-small text-sm px-2 py-2 me-2"
                                            >
                                                <GrEdit className={'h-4 w-4'} />
                                            </button>
                                            <button
                                                onClick={() => handleDeleteWall(index)}
                                                className="mx-auto text-red-800 border bg-white hover:bg-red-800 hover:text-white font-small text-sm px-2 py-2 me-2"
                                            >
                                                <GrTrash className={'h-4 w-4'} />
                                            </button>
                                        </div>
                                        {index > 0 &&
                                            <span
                                                className='bg-gray-200 rounded-full p-2.5 cursor-pointer hover:bg-gray-300 h-10 w-10'
                                                style={{ position: 'absolute', top: -20, right: 100 }}
                                                onClick={() => {
                                                    setShowAngleModal(wall.angle === '' ? 'cut' : wall.angle);
                                                }}
                                            >
                                                {wall.angle === 'inside' &&
                                                    <>
                                                        <GrTopCorner className={'absolute top-3 left-3'} />
                                                        <GrTopCorner className={'absolute top-4 left-4 text-orange-500'} />
                                                    </>
                                                }
                                                {wall.angle === 'outside' &&
                                                    <>
                                                        <GrBottomCorner className={'absolute top-2 left-2'} />
                                                        <GrBottomCorner className={'absolute top-3 left-3 text-orange-500'} />
                                                    </>
                                                }
                                                {wall.angle === '' &&
                                                    <GrFormCut style={{ fontSize: 20 }} />
                                                }
                                            </span>
                                        }
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {walls.length > 0 &&
                        <div className="text-center mt-4 border-t pt-5">
                            <button type="button" id="add-wall" className="text-white bg-orange-500 w-1/2 focus:ring-4 font-medium rounded-sm text-sm px-5 py-2.5 me-2 my-2"
                                onClick={() => setShowFormModal(true)}
                            >
                                {t('addWall')}
                            </button>
                        </div>
                    }
                    <div className="text-center mt-4 border-t py-5">
                        <button type="button" id="calculate-button" className="text-white mt-2 hover:bg-blue-800 w-1/2 font-medium rounded-sm text-sm px-5 py-2.5 me-2 mb-2" disabled={walls.width < 1} onClick={handleCalculate}>
                            {t('calculateProfileAmount')}</button>
                    </div>
                </div>
            )}

            {/* Show angle */}
            <Modal
                isOpen={showAngleModal}
                onRequestClose={() => setShowAngleModal(false)}
                title={t('Hoek informatie')}
            >
                {showAngleModal === 'cut' &&
                    <div className={"image-button mr-2 selected"}>
                        <img
                            style={{ objectFit: 'contain', cursor: 'default' }}
                            src={detachedColor}
                            alt="wallStatusNotConnected"
                        />
                        <label>{t('Niet aansluitend')}</label>
                    </div>
                }
                {showAngleModal === 'inside' &&
                    <div className={"image-button mr-2 selected"}>
                        <img
                            style={{ objectFit: 'contain', cursor: 'default' }}
                            src={attachedInnerCornerColor}
                            alt="wallStatusConnected"
                        />
                        <label>{t('Aansluitende binnenhoek')}</label>
                    </div>
                }
                {showAngleModal === 'outside' &&
                    <div className={"image-button mr-2 selected"}>
                        <img
                            style={{ objectFit: 'contain', cursor: 'default' }}
                            src={attachedOuterCornerColor}
                            alt="wallStatusConnected"
                        />
                        <label>{t('Aansluitende buitenhoek')}</label>
                    </div>
                }
            </Modal>

            {/* Form Modal */}
            <Modal
                isOpen={showFormModal}
                onRequestClose={() => {
                    setEditWallIndex(null);
                    setShowFormModal(false)
                }}
                title={editWallIndex === null ? t('new wall') : t('edit wall') + ' #' + (editWallIndex + 1)}
            >
                <CalculatorForm
                    units={units}
                    walls={walls}
                    editWallIndex={editWallIndex}
                    onAddWall={(newWall) => {
                        if (typeof newWall.index === "undefined" || newWall.index === null) {
                            console.log(newWall);
                            setWalls((prevWalls) => [...prevWalls, newWall]);
                        } else {
                            let newWalls = JSON.parse(JSON.stringify(walls));
                            delete newWall.index;
                            newWalls[editWallIndex] = newWall;
                            setWalls(newWalls);
                        }
                        setEditWallIndex(null);
                        setShowFormModal(false);
                    }}
                />
            </Modal>
        </>
    );
};