import './App.css';
import Result from './components/Result/Result.js';
import Header from './components/Header/Header.js';
import Calculator from './components/Calculator/Calculator.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n.js';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from './components/Footer/Footer.js';

function App() {
  const { t, i18n } = useTranslation();
  const [showVisualHelper, setShowVisualHelper] = useState(true);

  const handleLanguageChange = (languageCode) => {
    i18n.changeLanguage(languageCode);
    setShowVisualHelper(true);
  };
  return (
    <I18nextProvider i18n={i18n}>
      <div className="App bg-gray-100 min-h-screen relative">
        <Header onLanguageChange={handleLanguageChange} />
        <main className="flex justify-center items-center pb-16">
          <Router>
            <Routes>
              <Route path="/" element={<Calculator showVisualHelper={showVisualHelper} setShowVisualHelper={setShowVisualHelper} />} />
              <Route path="/result" element={<Result />} />
            </Routes>
          </Router>
        </main>
        <Footer />
      </div>
    </I18nextProvider>
  );
}

export default App;
