import React from 'react';

export default function Footer() {

    const handleClick = () => {
        window.open("https://www.aware.be", "_blank");
    };

    return (
        <footer className="absolute bottom-0 left-0 right-0">
            <div className="flex justify-center items-center h-12 shadow-md bg-white">
                <p className="color-primary text-sm">© {(new Date()).getFullYear()} Developed by <span className={'cursor-pointer hover:animate-pulse'} onClick={handleClick}>Aware</span></p>
            </div>
        </footer>
    );
}