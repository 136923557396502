import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import attachedInnerCornerColor from '../../images/attachedInnerCorner-Color.png';
import attachedInnerCornerBW from '../../images/attachedInnerCorner-B&W.png';
import attachedOuterCornerColor from '../../images/attachedOuterCorner-Color.png';
import attachedOuterCornerBW from '../../images/attachedOuterCorner-B&W.png';
import detachedColor from '../../images/detached-color.png';
import detachedBW from '../../images/detached-B&W.png';

export default function ProfilesCalculator({ units, walls, onAddWall, editWallIndex = null }) {

    const
        { t, i18n } = useTranslation(),
        widthRef = useRef(null),
        heightRef = useRef(null),
        [currentWall, setCurrentWall] = useState(editWallIndex !== null ? walls[editWallIndex] : {
            width: '',
            height: walls.length > 0 ? walls[0].height : '',
            isTouching: false,
            angle: ''
        }),
        [selectedOption, setSelectedOption] = useState('outside')
        ;

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        let inputValue = type === 'checkbox' ? checked : value;

        if (type === 'number') {
            inputValue = parseFloat(inputValue);
            if (inputValue < 0) {
                inputValue = inputValue * -1;
            }
        }

        setCurrentWall((prevWall) => ({
            ...prevWall,
            [name]: inputValue,
        }));
    };

    const setImage = (value) => {
        setCurrentWall((prevWall) => ({
            ...prevWall,
            isTouching: value,
        }));
    };

    const handleAddWall = () => {
        // Check if height and width are not empty
        if (currentWall.height !== '' && currentWall.width !== '') {
            if (currentWall.isTouching) {
                currentWall.angle = selectedOption;
            }

            let response = currentWall;
            if (editWallIndex !== null) {
                response = { index: editWallIndex, ...currentWall };
            }
            onAddWall(response);

            setCurrentWall({
                width: '',
                height: currentWall.height,
                isTouching: false,
                angle: ''
            });
        } else {
            alert('Height and Width values cannot be empty.');
        }
    };

    let restartProfileProps = {};
    if (currentWall.restartProfile) restartProfileProps = { checked: "checked" };
    return (
        <div>
            <form className='px-5'>
                <div className="mb-4" id="width">
                    <label htmlFor="width" className="block text-gray-500 font-semibold mb-2">
                        {t('width')}
                    </label>
                    <div className="relative">
                        <input
                            min={1}
                            type="number"
                            ref={widthRef}
                            name="width"
                            value={currentWall.width}
                            onChange={handleInputChange}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    heightRef.current.focus();
                                }
                            }}
                            placeholder={t('enterWidth')}
                            className="w-full border p-2 rounded custom-input-dimm"
                            required />
                        <div className="absolute custom-input-dimm-text inset-y-0 end-0 flex border items-center m-2 bg-gray-200 px-2">
                            {units}
                        </div>
                    </div>
                </div>

                <div className="mb-4" id="height">
                    <label htmlFor="height" className="block text-gray-500 font-semibold mb-2">
                        {t('height')}
                    </label>
                    <div className="relative">
                        <input
                            min={1}
                            type="number"
                            ref={heightRef}
                            name="height"
                            value={currentWall.height}
                            onChange={handleInputChange}
                            placeholder={t('enterHeight')}
                            className="w-full border p-2 rounded custom-input-dimm"
                            required />
                        <div className="absolute custom-input-dimm-text inset-y-0 end-0 flex border items-center m-2 bg-gray-200 px-2">
                            {units}
                        </div>
                    </div>
                </div>
                {walls.length > 0 && editWallIndex !== 0 && (
                    <div className="mb-4" id="isTouching">
                        <label htmlFor="height" className="block text-gray-500 font-semibold mb-2">
                            {t('adjoiningFacade')}
                        </label>
                        <div className="flex">
                            <div className={`image-button mr-2 ${currentWall.isTouching ? '' : 'selected'}`}>
                                <img
                                    style={{ objectFit: 'contain' }}
                                    src={`${currentWall.isTouching ? detachedBW : detachedColor}`}
                                    alt="wallStatusNotConnected"
                                    onClick={() => { setImage(false); }}
                                />
                                <label>{t('Niet aansluitend')}</label>
                            </div>
                            <div className={`image-button mr-2 ${currentWall.isTouching && selectedOption && selectedOption == 'inside' ? 'selected' : ''}`}>
                                <img
                                    style={{ objectFit: 'contain' }}
                                    src={`${currentWall.isTouching && selectedOption && selectedOption == 'inside' ? attachedInnerCornerColor : attachedInnerCornerBW}`}
                                    alt="wallStatusConnected"
                                    onClick={() => { setImage(true); setSelectedOption('inside') }}
                                />
                                <label>{t('Aansluitende binnenhoek')}</label>
                            </div>
                            <div className={`image-button mr-2 ${currentWall.isTouching && selectedOption && selectedOption == 'outside' ? 'selected' : ''}`}>
                                <img
                                    style={{ objectFit: 'contain' }}
                                    src={`${currentWall.isTouching && selectedOption && selectedOption == 'outside' ? attachedOuterCornerColor : attachedOuterCornerBW}`}
                                    alt="wallStatusConnected"
                                    onClick={() => { setImage(true); setSelectedOption('outside') }}
                                />
                                <label>{t('Aansluitende buitenhoek')}</label>
                            </div>
                        </div>
                    </div>
                )}

                {walls.length === 0 || editWallIndex === 0 || !currentWall.isTouching ?
                    <div className="mb-4" id="height">
                        <input id="restartProfile" name="restartProfile" onChange={handleInputChange} type="checkbox" {...restartProfileProps} class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2" />
                        <label for="restartProfile" class="ms-2 text-sm font-medium text-gray-900">{t('Vlak Startprofiel')}</label>    
                    </div>
                    : null}

                <div className="text-center">
                    <button
                        type="button"
                        onClick={handleAddWall}
                        id="add-wall"
                        className="text-white bg-orange-500 w-1/2 focus:ring-4 font-medium rounded-sm text-sm px-5 py-2.5 me-2 my-2"
                    >
                        {editWallIndex === null ? t('addWall') : t('editWall')}
                    </button>
                </div>
            </form>
        </div>
    );
};